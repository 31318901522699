import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Vad är en chokladbiskvi?',
    content: () => (
      <>
			En chokladbiskvi är ett delikat bakverk med tre huvudkomponenter: en seg mandelbotten, en krämig smörkrämsfyllning och ett krispigt täcke av choklad.
      </>
    ),
  },
  {
    title: 'Varför fira den 11 november?',
    content: () => (
      <>
				Den 11 november är redan etablerad som chokladens dag. Vi tycker dock att det är otydligt i vilken form det är tänkt att man ska äta choklad för att fira. Genom att låta chokladbiskvins dag sammanfalla med chokladens dag blir det tydligare för gemene man hur chokladens dag ska firas. Dessutom är ju glasyren på en chokladbiskvi det bästa sättet att använda choklad på.
      </>
    ),
  },
  {
    title: 'Är chokladbiskvier nyttiga?',
    content: () => (
      <>
			Ja, i alla fall för själen. Livsmedelsverket rekommenderar ett dagligt kaloriintag på 2000-2500 kcal. En biskvi innehåller ungefär 400 kcal, så man kan med gott samvete äta ett par biskvier om dagen.
      </>
    ),
  },
  {
    title: 'Vad skiljer chokladbiskvin från Sara Bernhardt',
    content: () => (
      <>
				Sara Bernhardt är en variant på chokladbiskvin som är fylld med chokladtryffel istället för smörkräm. Att fira chokladbiskvins dag genom att äta Sara Bernhardt är helt okej, även om gänget bakom chokladbiskvi.se såklart föredrar orginalet.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Vanliga frågor</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import RecipeItem from '@common/RecipeItem';

const RECIPEPART = [
  {
		title: 'Ingredienser',
    content: () => (
      <>
			<p>12-16 normalstora biskvier.</p><br/>
				<h3>Botten:</h3>
					<ul>
						<li>300 g mandelmassa</li>
						<li>1 äggvita</li>
					</ul>
				<h3>Fyllning:</h3>
					<ul>
						<li>125 g rumsvarmt smör</li>
						<li>1,5 dl florsocker</li>
						<li>2 tsk vaniljsocker</li>
						<li>2-3 tsk kakao</li>
						<li>1 äggula</li>
					</ul>
				<h3>Glasyr:</h3>
					<ul>
						<li>100-125 g mörk choklad</li>
						<li>1 msk smör</li>
					</ul>
      </>
    ),
  },
  {
    title: 'Gör såhär',
    content: () => (
      <>
				<h3>Botten:</h3>
					<p>
						Sätt ugnen på 175 &#8451;. Riv mandelmassan och blanda den med äggvitan till en smidig smet. Klicka ut smeten på en plåt täckt med bakplåtspapper. Platta till klickarna med mjölad hand så att de blir ca 5 cm i diameter. Grädda i mitten av ugnen 10-12 minuter. Lossa bottnarna från plåten och låt dem svalna på galler.
					</p>
					<br/>
				<h3>Fyllning:</h3>
					<p>
						Vispa ihop rumsvarmt smör, florsocker, vaniljsocker, äggula och kakao till en jämn och pösig kräm. Bred krämen på undersidan av bottnarna med en slickepott. Var noga med att få till en fin biskvi-form. Låt stå i kylen minst en timme.
					</p>
					<br/>
				<h3>Glasyr:</h3>
					<p>
						Bryt chokladen i bitar och smält över vattenbad i en djup skål. Blanda ner smöret. Doppa de kalla kakorna hastigt i chokladen så att fyllningen blir helt täckt. Vrid biskvin lite så att överflödig choklad kan rinna av. Ställ doppade biskvier på ett bakplåtspapper med chokladen uppåt. Förvara svalt i tätslutande burk (eller ät upp alla direkt).
					</p>
      </>
    ),
  },
];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="brands" accent>
        <StyledContainer>
          <div>
            <h1>Baka dina egna biskvier!</h1>
							<div>
								{RECIPEPART.map(({ title, content }) => (
									<RecipeItem title={title} key={title}>
										{content()}
									</RecipeItem>
								))}
							</div>
          </div>
          <Art>
            <Img
							fluid={data.art_story.childImageSharp.fluid}
							style={{ width: 480, maxWidth: '100%', marginBottom: -16 }}
						/>
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  justify-items: center;
  margin-top: 96px;

  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
	flex-direction: column;
  justify-content: flex-between;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 30px;
  margin-bottom: -16px;
`;

export default UsedBy;

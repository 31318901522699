import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const TEAM = [
  {
    name: 'Axel Löf',
    image: 'axel.jpg',
    role: 'axel@chokladbiskvi.se',
  },
  {
    name: 'Daniel Karlsson',
    image: 'daniel.jpg',
    role: 'daniel@chokladbiskvi.se',
  },
  {
    name: 'Kalle Svensson',
    image: 'kalle.jpg',
    role: 'kalle@chokladbiskvi.se',
  }
];

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="team">
        <StyledContainer>
					<TeamBox>
						<TeamText>
							<h1>Hjälp oss skapa en tradition!</h1>
								<p>När vi den 4 oktober 2019 firade kanelbullens dag konstaterade Daniel att kanelbullar egentligen är ganska tråkiga och ofta torra. Kalle höll med och sa att chokladbiskvier är så mycket godare. Axel frågade: "När firas chokladbiskvins dag då?" och googlade. Vi blev förfärade när vi insåg att chokladbiskvin inte har en egen dag. När chocken hade lagt sig bestämde vi oss för att ta saken i egna händer och se till att chokladbiskvin får högtidssdagen och uppmärksamheten den förtjänar.</p><br/>
								<p>Hjälp oss att göra chokladbiskvins dag till en tradition genom att fira tillsammans med vänner den 11 november (11/11) och dela med er av firandet på #chokladbiskvinsdag i sociala medier!</p><br/>
								<p>- Axel, Daniel och Kalle</p>
						</TeamText>
						<TeamGrid>
							{TEAM.map(({ name, image, role }) => {
								const img = data.allFile.edges.find(
									({ node }) => node.relativePath === image
								).node;

								return (
									<div>
										<Img fluid={img.childImageSharp.fluid} alt={name} />
										<Title>{name}</Title>
										<Subtitle>{role}</Subtitle>
									</div>
								);
							})}
						</TeamGrid>
					</TeamBox>
          <Art>
            <Img fluid={data.art_team.childImageSharp.fluid} />
          </Art>
          <ArtMobile>
            <Img fluid={data.art_team.childImageSharp.fluid} />
          </ArtMobile>
        </StyledContainer>
      </Section>
    )}
  />
);

const TeamBox = styled.div`
  display: flex;
	flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const TeamText = styled.div`
  width: 60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 60%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
	flex-direction: column;
  justify-content: flex-between;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

export default Team;

import React from 'react';
import Collapsible from 'react-collapsible';

import { Wrapper } from './style';

const RecipeItem = ({ title, children }) => (
  <Wrapper>
    <Collapsible
      className="recipe"
      openedClassName="recipe active"
      triggerClassName="recipe-title"
      triggerOpenedClassName="recipe-title active"
      triggerTagName="button"
      contentInnerClassName="recipe-content"
      trigger={title}
      transitionTime={300}
      easing="ease-out"
    >
      {children}
    </Collapsible>
  </Wrapper>
);

export default RecipeItem;
